import { Component, ElementRef, HostListener, Input } from '@angular/core';
// import { MessagingService } from "./shared/messaging.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
    message;

    private wasInside = false;

  text = "";

  @Input() makeDifferent;
  
  @HostListener('click')
  clickInside() {
    this.text = "clicked inside";
    this.wasInside = true;
  }
  
  @HostListener('document:click')
  clickout() {
    if (!this.wasInside) {
      this.text = "clicked outside";
    }
    this.wasInside = false;
  }
  
  

    constructor() {
      console.log("text", this.text);
    }

    ngOnInit() {
        console.log("text", this.text);
    }

    ngAfterViewInit() {

    }

}
