import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { LoginComponent } from './external/login/login.component';
import { ForgotPasswordnComponent } from './external/forgotPassword/forgotPassword.component';
import { PageNotFoundComponent } from './common/page-not-found/page-not-found.component';
import { AuthGuard } from './services/auth.guard';
// import { ListComponent } from './external/list/list.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'forgotPassword/:id', component: ForgotPasswordnComponent },
  { path: '',canActivate: [AuthGuard], loadChildren: './internal/internal.module#InternalModule'},
  { path: '**', component: PageNotFoundComponent },
 ];

@NgModule({
  imports: [ RouterModule.forRoot(routes,
      {preloadingStrategy: PreloadAllModules}) ],
  exports: [ RouterModule ]
})

export class AppRoutingModule { }
