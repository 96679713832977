import { Component, OnInit } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder,  Validators,  FormControl} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import * as CONSTANT from '../../services/constants';
import { loginModel, resetPasswordModel } from '../../shared/models/login-model';
import { AdminService } from '../../services/admin.service';
import { HttpParams } from '@angular/common/http';
import { apiUrl } from '../../global/global';
// import {MessagingService} from "../../shared/messaging.service";
import { API_END_POINT_Live, API_END_POINT_Dev } from 'src/app/services/global';


@Component({
  selector: 'app-forgotPassword',
  templateUrl: './forgotPassword.component.html',
  styleUrls: ['./forgotPassword.component.css']
})
export class ForgotPasswordnComponent implements OnInit {
    public form: FormGroup;
    public resetPasswordForm: FormGroup;
    showError  = false;
    showError1 = false;
    show = true;
    public loading = false;
    reset_password_token;

    loginModel = new loginModel();
    resetPasswordModel =  new resetPasswordModel();

    constructor(private formBuilder: FormBuilder,
                private router: Router,
                private api: AdminService,
                private route: ActivatedRoute
    ) {
        this.reset_password_token = this.route.snapshot.paramMap.get('id');
        this.form = this.formBuilder.group({
            password : ['', Validators.required],
            confirmPassword: ['', Validators.required],
        });


    }
    ngOnInit() {
        // console.log(localStorage.getItem('firebaseToken'));
        let data = localStorage.getItem('adminToken');
        this.show = true;
        if(data)
        this.router.navigate(['/dashboard']);
    }
    onSubmit(form) {
       // console.log("thr", form)
        if (form.valid) {
            this.loading = true;

            if (form.value.password !== form.value.confirmPassword) {
                this.showError1 = true;
                setTimeout(() => {
                    this.showError1 = false;
                }, 5000);
                return false;
            }
            const data = {
                'password': form.value.password,
                'password_reset_token': this.reset_password_token
              //  'deviceToken': this.msg.getFcmToken()
            };
            this.api.postData('admin/resetPassword', data)
                .subscribe(res => {
                        this.loading = false;
                        this.router.navigate(['/login']);
                        this.api.toast('Password Reset Successfull!', 'Vibes Admin');
                        // this.api.getData( apiUrl._getPermissionById,param).subscribe(
                        //     res => {
                        //         console.log(res.data.permission, "res.data.permission");
                        //         localStorage.setItem('permissionData', res.data.permission);
                        //         this.router.navigate(['/dashboard']);
                        //         this.api.toast('Login Successfull!', 'Vibes Admin');
                        //     });
                    },
                    err => {
                        console.log(err);
                        this.loading = false;
                    });
        } else {
           this.showError = true;
        }
    }

}
