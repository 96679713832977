import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {AsyncPipe, CommonModule} from '@angular/common';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';


import { AppComponent } from './app.component';
import { LoginComponent } from './external/login/login.component';

import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoadingModule } from 'ngx-loading';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { SocialLoginModule, AuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { ToastrModule } from 'ngx-toastr';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { InterceptorService } from './services/interceptor.service';
import { LightboxModule } from 'ngx-lightbox';
import { McBreadcrumbsModule } from 'ngx-breadcrumbs';
import { LoaderComponent } from './common/loader/loader.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { PageNotFoundComponent } from './common/page-not-found/page-not-found.component';
import { CKEditorModule } from 'ngx-ckeditor';
// import { ListComponent } from './external/list/list.component';
// import {MessagingService} from "./shared/messaging.service";
import { DataSharingService } from './services/data.share.service';
import { ForgotPasswordnComponent } from './external/forgotPassword/forgotPassword.component';
// import { ContactUsComponent } from './setting/contact-us/contact-us.component';



const config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider('')
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider('')
  }
]);

export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoaderComponent,
    PageNotFoundComponent,
    ForgotPasswordnComponent
    // ContactUsComponent,
  ],
  imports: [
    McBreadcrumbsModule.forRoot(),
    BrowserModule,
    NgxSpinnerModule,
    AppRoutingModule,
    HttpModule,
    HttpClientModule,
    CKEditorModule,
    FormsModule,
    ReactiveFormsModule,
      AngularMultiSelectModule,
    LoadingModule,
    NgbModule,
    CommonModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    SocialLoginModule,
      LightboxModule,
      AngularFontAwesomeModule,
      ToastrModule.forRoot({
          timeOut: 1500,
          positionClass: 'toast-top-right',
          preventDuplicates: true,
      }),
      SweetAlert2Module.forRoot({
          buttonsStyling: false,
          customClass: 'modal-content',
          confirmButtonClass: 'btn btn-primary',
          cancelButtonClass: 'btn'
      }),
      ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [ AsyncPipe, {
    provide: HTTP_INTERCEPTORS,
    useClass: InterceptorService,
    multi: true
  },
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    }, NgxSpinnerModule,
    DataSharingService
  ],
  bootstrap: [AppComponent],
})

export class AppModule { }
