// export const environment = {
//     production: false,
//     apiBaseUrl: 'https://app.ctmconnects.com/'
// };


export const environment = {
    production: false,
    firebase: {
        apiKey: "AIzaSyDGyqr1yq8z3kkLa2F9vP6b2ietfLzuexI",
        authDomain: "helpo-5dbd3.firebaseapp.com",
        databaseURL: "https://helpo-5dbd3.firebaseio.com",
        projectId: "helpo-5dbd3",
        storageBucket: "helpo-5dbd3.appspot.com",
        messagingSenderId: "688550891229",
        appId: "1:688550891229:web:ed25cbaac89dabecf35534"
    }
};